import mnetPlusLogo from '../images/logos/mnet_plus_logo.png';

// Import images for first tab content
import firstTabEn from '../images/first_tab_content/first_tab_content_en.webp';
import firstTabJa from '../images/first_tab_content/first_tab_content_ja.webp';
import firstTabKo from '../images/first_tab_content/first_tab_content_ko.webp';
import firstTabCnZh from '../images/first_tab_content/first_tab_content_zh-cn.webp';
import firstTabCnTw from '../images/first_tab_content/first_tab_content_zh-tw.webp';

// Import images for second tab content
import secondTabEn from '../images/second_tab_content/content/second_tab_content_en.webp';
import secondTabJa from '../images/second_tab_content/content/second_tab_content_ja.webp';
import secondTabKo from '../images/second_tab_content/content/second_tab_content_ko.webp';
import secondTabCnZh from '../images/second_tab_content/content/second_tab_content_zh-cn.webp';
import secondTabCnTw from '../images/second_tab_content/content/second_tab_content_zh-tw.webp';

// Import images for apply content
import applyKoContentEn from '../images/apply_k/content/apply_k_en.webp';
import applyKoContentJa from '../images/apply_k/content/apply_k_ja.webp';
import applyKoContentKo from '../images/apply_k/content/apply_k_ko.webp';
import applyKoContentCnZh from '../images/apply_k/content/apply_k_zh-cn.webp';
import applyKoContentCnTw from '../images/apply_k/content/apply_k_zh-tw.webp';

import applyCnContentEn from '../images/apply_c/content/apply_c_en.webp';
import applyCnContentJa from '../images/apply_c/content/apply_c_ja.webp';
import applyCnContentKo from '../images/apply_c/content/apply_c_ko.webp';
import applyCnContentCnZh from '../images/apply_c/content/apply_c_zh-cn.webp';
import applyCnContentCnTw from '../images/apply_c/content/apply_c_zh-tw.webp';

// Import tab bar images
import tabBarFirstEn from '../images/tab_bar/first_selected/tabs_en.webp';
import tabBarFirstJa from '../images/tab_bar/first_selected/tabs_ja.webp';
import tabBarFirstKo from '../images/tab_bar/first_selected/tabs_ko.webp';
import tabBarFirstCnZh from '../images/tab_bar/first_selected/tabs_zh-cn.webp';
import tabBarFirstCnTw from '../images/tab_bar/first_selected/tabs_zh-tw.webp';

import tabBarSecondEn from '../images/tab_bar/second_selected/tabs_en.webp';
import tabBarSecondJa from '../images/tab_bar/second_selected/tabs_ja.webp';
import tabBarSecondKo from '../images/tab_bar/second_selected/tabs_ko.webp';
import tabBarSecondCnZh from '../images/tab_bar/second_selected/tabs_zh-cn.webp';
import tabBarSecondCnTw from '../images/tab_bar/second_selected/tabs_zh-tw.webp';

// Import common default FAQ images
import faqDefaultEn from '../images/faq_closed_buttons/faq_closed_btn_non_ja.webp';
import faqDefaultJa from '../images/faq_closed_buttons/faq_closed_btn_ja.webp';
import faqDefaultKo from '../images/faq_closed_buttons/faq_closed_btn_non_ja.webp';
import faqDefaultCnZh from '../images/faq_closed_buttons/faq_closed_btn_non_ja.webp';
import faqDefaultCnTw from '../images/faq_closed_buttons/faq_closed_btn_non_ja.webp';

// Import Second Tab expanded FAQ images
import secondTabFaqExpandedEn from '../images/second_tab_content/faq/faq_expanded_en.webp';
import secondTabFaqExpandedJa from '../images/second_tab_content/faq/faq_expanded_ja.webp';
import secondTabFaqExpandedKo from '../images/second_tab_content/faq/faq_expanded_ko.webp';
import secondTabFaqExpandedCnZh from '../images/second_tab_content/faq/faq_expanded_zh-cn.webp';
import secondTabFaqExpandedCnTw from '../images/second_tab_content/faq/faq_expanded_zh-tw.webp';

// Import Apply expanded FAQ images
import applyKoFaqExpandedEn from '../images/apply_k/faq/apply_k_faq_expanded_en.webp';
import applyKoFaqExpandedJa from '../images/apply_k/faq/apply_k_faq_expanded_ja.webp';
import applyKoFaqExpandedKo from '../images/apply_k/faq/apply_k_faq_expanded_ko.webp';
import applyKoFaqExpandedCnZh from '../images/apply_k/faq/apply_k_faq_expanded_zh-cn.webp';
import applyKoFaqExpandedCnTw from '../images/apply_k/faq/apply_k_faq_expanded_zh-tw.webp';

import applyCnFaqExpandedEn from '../images/apply_c/faq/apply_c_faq_expanded_en.webp';
import applyCnFaqExpandedJa from '../images/apply_c/faq/apply_c_faq_expanded_ja.webp';
import applyCnFaqExpandedKo from '../images/apply_c/faq/apply_c_faq_expanded_ko.webp';
import applyCnFaqExpandedCnZh from '../images/apply_c/faq/apply_c_faq_expanded_zh-cn.webp';
import applyCnFaqExpandedCnTw from '../images/apply_c/faq/apply_c_faq_expanded_zh-tw.webp';

const videoConfig = {
    chinese:
        '//player.bilibili.com/player.html?isOutside=true&bvid=BV1AnUDYGEWx&&p=1', // Video A for zh-cn and zh-tw
    default: 'https://www.youtube.com/embed/KRaQRiKJUc0', // Video B for other locales
};

const styleConfig = {
    firstTab: {
        youtube: {
            en: { top: '16.9%', left: '50%', width: '85.65%', height: '10%' },
            ja: { top: '17.4%', left: '50%', width: '85.65%', height: '10.5%' },
            ko: { top: '17.5%', left: '50%', width: '85.65%', height: '10.3%' },
            'zh-cn': {
                top: '17.9%',
                left: '50%',
                width: '85.65%',
                height: '10.6%',
            },
            'zh-tw': {
                top: '17.9%',
                left: '50%',
                width: '85.8%',
                height: '10.5%',
            },
        },
        nextButton: {
            en: {
                right: '7%',
                top: '55%',
                width: '86%',
                height: '3.6%',
            },
            ko: {
                right: '7%',
                top: '53.85%',
                width: '86%',
                height: '3.6%',
            },
            ja: {
                right: '7%',
                top: '54.4%',
                width: '86%',
                height: '3.6%',
            },
            'zh-cn': {
                right: '7%',
                top: '54.2%',
                width: '86%',
                height: '3.6%',
            },
            'zh-tw': {
                right: '7%',
                top: '54.15%',
                width: '86%',
                height: '3.6%',
            },
        },
    },
    secondTab: {
        applyButtons: {
            en: {
                korean: {
                    top: '77%',
                    left: '10.3%',
                    width: '35%',
                    height: '13.4%',
                },
                chinese: {
                    top: '77%',
                    right: '10.3%',
                    width: '35%',
                    height: '13.4%',
                },
            },
            ja: {
                korean: {
                    top: '75.6%',
                    left: '10.3%',
                    width: '35%',
                    height: '14.2%',
                },
                chinese: {
                    top: '75.6%',
                    right: '10.3%',
                    width: '35%',
                    height: '14.2%',
                },
            },
            ko: {
                korean: {
                    top: '77.0%',
                    left: '10.5%',
                    width: '35%',
                    height: '13.7%',
                },
                chinese: {
                    top: '77.0%',
                    right: '10.5%',
                    width: '35%',
                    height: '13.7%',
                },
            },
            'zh-cn': {
                korean: {
                    top: '76.8%',
                    left: '10.5%',
                    width: '35%',
                    height: '15%',
                },
                chinese: {
                    top: '76.8%',
                    right: '10.5%',
                    width: '35%',
                    height: '15%',
                },
            },
            'zh-tw': {
                korean: {
                    top: '75.3%',
                    left: '10.7%',
                    width: '35%',
                    height: '14.5%',
                },
                chinese: {
                    top: '75.3%',
                    right: '10.7%',
                    width: '35%',
                    height: '14.5%',
                },
            },
        },
    },
    apply: {
        links: {
            en: {
                link1: {
                    top: '31.7%',
                    left: '11%',
                    width: '37.5%',
                    height: '2.74%',
                    borderRadius: '30px',
                },
                link2: {
                    top: '31.7%',
                    right: '11%',
                    width: '37.5%',
                    height: '2.74%',
                    borderRadius: '30px',
                },
                link3: {
                    top: '35.3%',
                    left: '11%',
                    width: '37.5%',
                    height: '2.74%',
                    borderRadius: '30px',
                }, // Japanese - bottom left
                link4: {
                    top: '35.3%',
                    right: '11%',
                    width: '37.5%',
                    height: '2.74%',
                    borderRadius: '30px',
                },
            },
            ko: {
                link1: {
                    top: '30.73%',
                    left: '11%',
                    width: '37.5%',
                    height: '2.74%',
                    borderRadius: '30px',
                },
                link2: {
                    top: '30.73%',
                    right: '11%',
                    width: '37.5%',
                    height: '2.74%',
                    borderRadius: '30px',
                },
                link3: {
                    top: '34.35%',
                    left: '11%',
                    width: '37.5%',
                    height: '2.74%',
                    borderRadius: '30px',
                }, // Japanese - bottom left
                link4: {
                    top: '34.35%',
                    right: '11%',
                    width: '37.5%',
                    height: '2.74%',
                    borderRadius: '30px',
                },
            },
            ja: {
                link1: {
                    top: '31.2%',
                    left: '11%',
                    width: '37.5%',
                    height: '2.74%',
                    borderRadius: '30px',
                },
                link2: {
                    top: '31.2%',
                    right: '11%',
                    width: '37.5%',
                    height: '2.74%',
                    borderRadius: '30px',
                },
                link3: {
                    top: '34.85%',
                    left: '11%',
                    width: '37.5%',
                    height: '2.74%',
                    borderRadius: '30px',
                }, // Japanese - bottom left
                link4: {
                    top: '34.85%',
                    right: '11%',
                    width: '37.5%',
                    height: '2.74%',
                    borderRadius: '30px',
                },
            },

            'zh-cn': {
                link1: {
                    top: '31.85%',
                    left: '11%',
                    width: '37.5%',
                    height: '2.78%',
                    borderRadius: '30px',
                },
                link2: {
                    top: '31.85%',
                    right: '11%',
                    width: '37.5%',
                    height: '2.78%',
                    borderRadius: '30px',
                },
                link3: {
                    top: '35.65%',
                    left: '11%',
                    width: '37.5%',
                    height: '2.78%',
                    borderRadius: '30px',
                }, // Japanese - bottom left
                link4: {
                    top: '35.65%',
                    right: '11%',
                    width: '37.5%',
                    height: '2.78%',
                    borderRadius: '30px',
                },
            },
            'zh-tw': {
                link1: {
                    top: '31.5%',
                    left: '11%',
                    width: '37.5%',
                    height: '2.74%',
                    borderRadius: '30px',
                },
                link2: {
                    top: '31.5%',
                    right: '11%',
                    width: '37.5%',
                    height: '2.74%',
                    borderRadius: '30px',
                },
                link3: {
                    top: '35.2%',
                    left: '11%',
                    width: '37.5%',
                    height: '2.74%',
                    borderRadius: '30px',
                }, // Japanese - bottom left
                link4: {
                    top: '35.2%',
                    right: '11%',
                    width: '37.5%',
                    height: '2.74%',
                    borderRadius: '30px',
                },
            },
        },
    },
};

// Create image maps
const contentImages = {
    first: {
        en: firstTabEn,
        ja: firstTabJa,
        ko: firstTabKo,
        'zh-cn': firstTabCnZh,
        'zh-tw': firstTabCnTw,
    },
    second: {
        en: secondTabEn,
        ja: secondTabJa,
        ko: secondTabKo,
        'zh-cn': secondTabCnZh,
        'zh-tw': secondTabCnTw,
    },
};

const tabBarImages = {
    first: {
        en: tabBarFirstEn,
        ja: tabBarFirstJa,
        ko: tabBarFirstKo,
        'zh-cn': tabBarFirstCnZh,
        'zh-tw': tabBarFirstCnTw,
    },
    second: {
        en: tabBarSecondEn,
        ja: tabBarSecondJa,
        ko: tabBarSecondKo,
        'zh-cn': tabBarSecondCnZh,
        'zh-tw': tabBarSecondCnTw,
    },
};

const applyContentImages = {
    ko: {
        en: applyKoContentEn,
        ja: applyKoContentJa,
        ko: applyKoContentKo,
        'zh-cn': applyKoContentCnZh,
        'zh-tw': applyKoContentCnTw,
    },
    cn: {
        en: applyCnContentEn,
        ja: applyCnContentJa,
        ko: applyCnContentKo,
        'zh-cn': applyCnContentCnZh,
        'zh-tw': applyCnContentCnTw,
    },
};

const faqImages = {
    default: {
        en: faqDefaultEn,
        ja: faqDefaultJa,
        ko: faqDefaultKo,
        'zh-cn': faqDefaultCnZh,
        'zh-tw': faqDefaultCnTw,
    },
    expanded: {
        secondTab: {
            en: secondTabFaqExpandedEn,
            ja: secondTabFaqExpandedJa,
            ko: secondTabFaqExpandedKo,
            'zh-cn': secondTabFaqExpandedCnZh,
            'zh-tw': secondTabFaqExpandedCnTw,
        },
        apply: {
            ko: {
                // Korean apply
                en: applyKoFaqExpandedEn,
                ja: applyKoFaqExpandedJa,
                ko: applyKoFaqExpandedKo,
                'zh-cn': applyKoFaqExpandedCnZh,
                'zh-tw': applyKoFaqExpandedCnTw,
            },
            cn: {
                // Chinese apply
                en: applyCnFaqExpandedEn,
                ja: applyCnFaqExpandedJa,
                ko: applyCnFaqExpandedKo,
                'zh-cn': applyCnFaqExpandedCnZh,
                'zh-tw': applyCnFaqExpandedCnTw,
            },
        },
    },
};

const externalLinksConfig = {
    ko: {
        link1: 'https://form.typeform.com/to/CyO2hYFS', // Korean form
        link2: 'https://form.typeform.com/to/jwge4Z2w', // English form
        link3: 'https://form.typeform.com/to/XCHsjWlj', // Japanese form
        link4: 'https://form.typeform.com/to/Biz1bvqk', // Chinese form
    },
    cn: {
        link1: 'https://form.typeform.com/to/IoJKlPGD', // Korean form
        link2: 'https://form.typeform.com/to/Zk88jRBc', // English form
        link3: 'https://form.typeform.com/to/t0kx96ZU', // Japanese form
        link4: 'https://form.typeform.com/to/N6Yl5BvD', // Chinese form
    },
};

export class ContentManager {
    constructor(urlManager) {
        this.urlManager = urlManager;
        this.previousState = null;
        this.preloadedImages = new Map();
        this.faqStates = {
            secondTab: false,
            apply: false,
        };

        this.preloadedVideos = {
            // Add this
            chinese: null,
            default: null,
        };

        this.preloadAllImages();
        this.preloadVideos();

        this.initializeContent();
        this.setupEventListeners();
    }

    preloadAllImages() {
        // Preload content images
        ['first', 'second'].forEach((tab) => {
            Object.entries(contentImages[tab]).forEach(([locale, imageSrc]) => {
                const img = new Image();
                img.src = imageSrc;
                this.preloadedImages.set(`content_${tab}_${locale}`, img);
            });
        });

        // Preload tab bar images
        ['first', 'second'].forEach((tab) => {
            Object.entries(tabBarImages[tab]).forEach(([locale, imageSrc]) => {
                const img = new Image();
                img.src = imageSrc;
                this.preloadedImages.set(`tabbar_${tab}_${locale}`, img);
            });
        });

        // Preload default FAQ images
        Object.entries(faqImages.default).forEach(([locale, imageSrc]) => {
            const img = new Image();
            img.src = imageSrc;
            this.preloadedImages.set(`faq_default_${locale}`, img);
        });

        // Preload second tab expanded FAQ images
        Object.entries(faqImages.expanded.secondTab).forEach(
            ([locale, imageSrc]) => {
                const img = new Image();
                img.src = imageSrc;
                this.preloadedImages.set(
                    `faq_expanded_secondTab_${locale}`,
                    img
                );
            }
        );

        // Preload apply content images
        Object.entries(applyContentImages).forEach(
            ([applyLang, localeImages]) => {
                Object.entries(localeImages).forEach(([locale, imageSrc]) => {
                    const img = new Image();
                    img.src = imageSrc;
                    this.preloadedImages.set(
                        `apply_content_${applyLang}_${locale}`,
                        img
                    );
                });
            }
        );

        // Preload apply expanded FAQ images for both Korean and Chinese
        ['ko', 'cn'].forEach((applyLang) => {
            Object.entries(faqImages.expanded.apply[applyLang]).forEach(
                ([locale, imageSrc]) => {
                    const img = new Image();
                    img.src = imageSrc;
                    this.preloadedImages.set(
                        `faq_expanded_apply_${applyLang}_${locale}`,
                        img
                    );
                }
            );
        });
    }

    setupLanguageDropdown() {
        const dropdown = document.getElementById('language-dropdown');
        const selectedLang = document.getElementById('selected-lang');
        const select = document.getElementById('localeSelect');

        // Set initial language text
        const languageMap = {
            en: 'English',
            ja: '日本語',
            ko: '한국어',
            'zh-cn': '简体中文',
            'zh-tw': '繁體中文',
        };

        // Update selected language highlight
        const updateSelectedStyle = (currentLang) => {
            // Remove highlight from all options
            document.querySelectorAll('.dropdown-option').forEach((option) => {
                if (option.dataset.lang === currentLang) {
                    option.style.color = 'rgba(255, 255, 255, 1)';
                    option.style.fontWeight = '700';
                } else {
                    option.style.color = 'rgba(255, 255, 255, 0.4)';
                    option.style.fontWeight = '500';
                }
            });
        };

        // Initial setup
        const setSelectedText = () => {
            const currentLang = select.value;
            selectedLang.textContent = languageMap[currentLang];
            document.body.className = `lang-${currentLang}`;
            updateSelectedStyle(currentLang); // Add this line
        };
        setSelectedText();

        // Handle dropdown clicks
        dropdown.addEventListener('click', (e) => {
            if (e.target.classList.contains('dropdown-option')) {
                const lang = e.target.dataset.lang;
                // Update the hidden select
                select.value = lang;
                setSelectedText();
                dropdown.classList.remove('active');

                // Trigger change event on select
                select.dispatchEvent(new Event('change'));
            } else {
                dropdown.classList.toggle('active');
            }
        });

        // Close dropdown when clicking outside
        document.addEventListener('click', (e) => {
            if (!dropdown.contains(e.target)) {
                dropdown.classList.remove('active');
            }
        });
    }

    preloadVideos() {
        const container = document.querySelector('.youtube-container');
        if (!container) return;

        const wrapper = document.createElement('div');
        wrapper.style.position = 'relative';
        wrapper.style.width = '100%';
        wrapper.style.height = '100%';
        container.appendChild(wrapper);

        ['chinese', 'default'].forEach((type) => {
            const iframe = document.createElement('iframe');
            iframe.width = '100%';
            iframe.height = '100%';
            iframe.src = videoConfig[type];
            iframe.frameBorder = '0';
            iframe.allowFullscreen = true;
            iframe.style.position = 'absolute';
            iframe.style.top = '0';
            iframe.style.left = '0';
            iframe.style.transition = 'opacity 0.3s ease';
            iframe.style.opacity = '0';
            iframe.style.display = 'none';

            wrapper.appendChild(iframe);
            this.preloadedVideos[type] = iframe;
        });
    }

    updateVideoSource(locale) {
        const isChineseLang = ['zh-cn', 'zh-tw'].includes(locale);
        const activeVideo = isChineseLang ? 'chinese' : 'default';
        const inactiveVideo = isChineseLang ? 'default' : 'chinese';

        if (
            this.preloadedVideos[activeVideo] &&
            this.preloadedVideos[inactiveVideo]
        ) {
            this.preloadedVideos[activeVideo].style.display = 'block';
            setTimeout(() => {
                this.preloadedVideos[activeVideo].style.opacity = '1';
            }, 0);

            this.preloadedVideos[inactiveVideo].style.opacity = '0';
            setTimeout(() => {
                this.preloadedVideos[inactiveVideo].style.display = 'none';
            }, 300);
        }
    }

    updateTabBarImage(tab = 'first') {
        const locale = this.urlManager.getParam('locale') || 'en';
        const tabBarImage = document.getElementById('tabImage');

        const imageSrc = tabBarImages[tab]?.[locale];

        if (imageSrc) {
            tabBarImage.src = imageSrc;
            // Add aria-label for accessibility
            tabBarImage.setAttribute(
                'aria-label',
                `Switch to ${tab === 'first' ? 'second' : 'first'} tab`
            );
        } else {
            console.error(
                `No tab bar image found for tab: ${tab}, locale: ${locale}`
            );
        }
    }

    updateApplyImage(applyLang, locale) {
        const applyImage = document.getElementById('applyImage');

        if (applyLang && locale) {
            const imageSrc = applyContentImages[applyLang][locale];

            if (imageSrc && applyImage) {
                applyImage.src = imageSrc;
            } else {
                console.error(
                    `No apply content image found for applyLang: ${applyLang}, locale: ${locale}`
                );
            }
        } else if (!applyLang && locale) {
            // No applyLang provided, use the default content image
            const imageSrc = contentImages['first'][locale];
            if (imageSrc && applyImage) {
                applyImage.src = imageSrc;
            } else {
                console.error(`No content image found for locale: ${locale}`);
            }
        } else {
            console.error(
                'updateApplyImage: applyLang and locale parameters are required.'
            );
        }
    }

    updateExternalLinks(applyLang) {
        const links = externalLinksConfig[applyLang];
        if (!links) {
            console.error(`No links found for applyLang: ${applyLang}`);
            return;
        }

        const buttons = document.querySelectorAll(
            '#applyContent .action-button'
        );
        if (buttons.length >= 4) {
            buttons[0].onclick = () => window.open(links.link1); // Korean form
            buttons[1].onclick = () => window.open(links.link2); // English form
            buttons[2].onclick = () => window.open(links.link3); // Japanese form
            buttons[3].onclick = () => window.open(links.link4); // Chinese form
        }
    }

    updatePositions(locale) {
        this.updateFirstTabPositions(locale);
        this.updateSecondTabPositions(locale);
        this.updateApplyPositions(locale);
    }

    updateFirstTabPositions(locale) {
        // Update YouTube container
        const youtubeContainer = document.querySelector('.youtube-container');
        const youtubeStyle = styleConfig.firstTab.youtube[locale];
        if (youtubeContainer && youtubeStyle) {
            Object.assign(youtubeContainer.style, youtubeStyle);
        }

        // Update Next button
        const nextButton = document.querySelector(
            '#firstTabContent .action-button'
        );
        const nextButtonStyle = styleConfig.firstTab.nextButton[locale];
        if (nextButton && nextButtonStyle) {
            Object.assign(nextButton.style, nextButtonStyle);
        }
    }

    updateSecondTabPositions(locale) {
        const buttons = document.querySelectorAll(
            '#secondTabContent .action-button'
        );
        const styles = styleConfig.secondTab.applyButtons[locale];

        if (buttons.length >= 2 && styles) {
            Object.assign(buttons[0].style, styles.korean);
            Object.assign(buttons[1].style, styles.chinese);
        }
    }
    updateFaqImage(locale, context = 'secondTab') {
        const faqImage = document.getElementById(`${context}Faq`);
        let imageSrc;

        if (!this.faqStates[context]) {
            imageSrc = faqImages.default[locale];
        } else {
            if (context === 'secondTab') {
                imageSrc = faqImages.expanded.secondTab[locale];
            } else if (context === 'apply') {
                const applyLang = this.urlManager.getParam('applyLang');
                imageSrc = faqImages.expanded.apply[applyLang][locale];
            }
        }

        if (imageSrc && faqImage) {
            faqImage.src = imageSrc;
            faqImage.style.cursor = 'pointer';
        }
    }

    updateApplyPositions(locale) {
        const buttons = document.querySelectorAll(
            '#applyContent .action-button'
        );
        const styles = styleConfig.apply.links[locale];

        if (buttons.length >= 4 && styles) {
            Object.assign(buttons[0].style, styles.link1);
            Object.assign(buttons[1].style, styles.link2);
            Object.assign(buttons[2].style, styles.link3);
            Object.assign(buttons[3].style, styles.link4);
        }
    }

    initializeContent() {
        document.getElementById('mnetPlusLogo').src = mnetPlusLogo;

        const locale = this.urlManager.getParam('locale');
        const tab = this.urlManager.getParam('tab') || 'first';
        const applyLang = this.urlManager.getParam('applyLang');

        if (applyLang && !this.previousState) {
            this.previousState = {
                tab: 'second',
                locale,
            };
        }

        document.getElementById('localeSelect').value = locale;
        this.setupLanguageDropdown();
        this.updateTabBarImage(tab);
        this.showContent(tab, applyLang);
        this.updateContentImages(locale);
        this.updateApplyImage(applyLang, locale);
        this.updateBackButton(applyLang);
        this.updatePositions(locale);
        this.updateVideoSource(locale);

        // Initialize both FAQ images
        this.updateFaqImage(locale, 'secondTab');
        this.updateFaqImage(locale, 'apply');
    }

    setupEventListeners() {
        document
            .getElementById('localeSelect')
            .addEventListener('change', (e) => {
                const newLocale = e.target.value;
                this.urlManager.setParam('locale', newLocale);
                this.updateContentImages(newLocale);
                this.updateTabBarImage(
                    this.urlManager.getParam('tab') || 'first'
                );
                this.updatePositions(newLocale);
            });

        document.querySelector('.back-button').addEventListener('click', () => {
            this.handleBack();
        });

        // Add click handler for tab bar
        document.getElementById('tabImage').addEventListener('click', (e) => {
            // Get current tab
            const currentTab = this.urlManager.getParam('tab') || 'first';
            // Toggle between tabs
            const newTab = currentTab === 'first' ? 'second' : 'first';

            // Only switch if we're not in apply mode
            if (!this.urlManager.getParam('applyLang')) {
                window.app.switchTab(newTab);

                window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                    // Using smooth behavior for a nicer transition
                });
            }
        });

        // Second Tab FAQ click handler
        document
            .getElementById('secondTabFaq')
            ?.addEventListener('click', () => {
                this.faqStates.secondTab = !this.faqStates.secondTab;
                const locale = this.urlManager.getParam('locale') || 'en';
                this.updateFaqImage(locale, 'secondTab');

                if (this.faqStates.secondTab) {
                    const faqElement = document.getElementById('secondTabFaq');
                    const offset = faqElement.offsetTop - 20; // Scroll to slightly above the FAQ
                    setTimeout(() => {
                        window.scrollTo({
                            top: offset,
                            behavior: 'smooth',
                        });
                    }, 100); // Small delay to ensure animation starts after FAQ expands
                }
            });

        // Apply FAQ click handler
        document.getElementById('applyFaq')?.addEventListener('click', () => {
            this.faqStates.apply = !this.faqStates.apply;
            const locale = this.urlManager.getParam('locale') || 'en';
            this.updateFaqImage(locale, 'apply');

            // Add scroll behavior when FAQ is expanded
            if (this.faqStates.apply) {
                const faqElement = document.getElementById('applyFaq');
                const offset = faqElement.offsetTop - 20; // Scroll to slightly above the FAQ
                setTimeout(() => {
                    window.scrollTo({
                        top: offset,
                        behavior: 'smooth',
                    });
                }, 100); // Small delay to ensure animation starts after FAQ expands
            }
        });
        // Update FAQ image when locale changes
        document
            .getElementById('localeSelect')
            .addEventListener('change', (e) => {
                const newLocale = e.target.value;
                this.urlManager.setParam('locale', newLocale);
                this.updateContentImages(newLocale);
                this.updateTabBarImage(
                    this.urlManager.getParam('tab') || 'first'
                );
                this.updatePositions(newLocale);
                this.updateVideoSource(newLocale); // Add this line

                // FAQ update logic
                const applyLang = this.urlManager.getParam('applyLang');
                if (applyLang) {
                    this.updateFaqImage(newLocale, 'apply');
                    this.updateApplyImage(applyLang, newLocale); // Update applyImage
                } else {
                    this.updateFaqImage(newLocale, 'secondTab');
                }
            });
    }

    updateContentImages(locale) {
        const firstTabImageSrc = contentImages.first[locale];
        const secondTabImageSrc = contentImages.second[locale];

        if (firstTabImageSrc) {
            document.getElementById('firstTabImage').src = firstTabImageSrc;
        }

        if (secondTabImageSrc) {
            document.getElementById('secondTabImage').src = secondTabImageSrc;
        }
    }

    showContent(tab, applyLang) {
        // Hide all content sections first
        document.getElementById('firstTabContent').style.display = 'none';
        document.getElementById('secondTabContent').style.display = 'none';
        document.getElementById('applyContent').style.display = 'none';

        // Reset FAQ states when switching content
        this.faqStates.secondTab = false;
        this.faqStates.apply = false;

        // Handle tab visibility
        const tabsElement = document.querySelector('.tabs');
        if (applyLang) {
            this.updateExternalLinks(applyLang);
            tabsElement.style.display = 'none';
            document.getElementById('applyContent').style.display = 'block';
            window.scrollTo({ top: 0, behavior: 'smooth' });
        } else {
            tabsElement.style.display = 'block';
            document.getElementById(`${tab}TabContent`).style.display = 'block';
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }

        // Update FAQ images for both contexts
        const locale = this.urlManager.getParam('locale') || 'en';
        this.updateFaqImage(locale, 'secondTab');
        this.updateFaqImage(locale, 'apply');
        this.updateApplyImage(applyLang, locale);
    }

    updateBackButton(applyLang) {
        const logo = document.querySelector('.company-logo');
        const backButton = document.querySelector('.back-button');

        if (this.isMobileWebView()) {
            logo.style.display = 'none';
            backButton.style.display = 'inline-block';

            // Remove existing listeners
            backButton.replaceWith(backButton.cloneNode(true));
            const newBackButton = document.querySelector('.back-button');

            if (applyLang) {
                // When on apply page
                newBackButton.addEventListener('click', () =>
                    this.handleBack()
                );
            } else {
                // Only add close handler when not navigating back
                newBackButton.addEventListener(
                    'click',
                    this.handleWebViewClose
                );
            }
        } else {
            // Regular web handling...
            if (applyLang) {
                logo.style.display = 'none';
                backButton.style.display = 'inline-block';
                backButton.addEventListener('click', () => this.handleBack());
            } else {
                logo.style.display = 'inline-block';
                backButton.style.display = 'none';
                logo.addEventListener('click', this.handleLogoClick);
            }
        }
    }
    isMobileWebView() {
        return (
            typeof window.MnetPlusApp !== 'undefined' &&
            window.MnetPlusApp.startsWith('Mnet Plus/')
        );
    }

    handleWebViewClose = () => {
        window.ReactNativeWebView.postMessage('{"command":"close"}');
    };

    handleLogoClick = () => {
        window.open('https://mnetplus.world/main', '_blank');
    };

    storePreviousState() {
        this.previousState = {
            tab: this.urlManager.getParam('tab') || 'first',
            locale: this.urlManager.getParam('locale'),
        };
    }

    handleBack() {
        if (this.previousState) {
            // Show tabs when going back
            document.querySelector('.tabs').style.display = 'block';

            this.urlManager.setParam('applyLang', null);
            this.urlManager.setParam('tab', this.previousState.tab);

            this.updateTabBarImage(this.previousState.tab);
            this.showContent(this.previousState.tab, null);

            // Just update UI without adding new event listeners yet
            const logo = document.querySelector('.company-logo');
            const backButton = document.querySelector('.back-button');

            if (this.isMobileWebView()) {
                logo.style.display = 'none';
                backButton.style.display = 'inline-block';
            } else {
                logo.style.display = 'inline-block';
                backButton.style.display = 'none';
            }

            this.previousState = null;

            // Now add the close handler after navigation is complete
            if (this.isMobileWebView()) {
                setTimeout(() => {
                    backButton.replaceWith(backButton.cloneNode(true));
                    const newBackButton =
                        document.querySelector('.back-button');
                    newBackButton.addEventListener(
                        'click',
                        this.handleWebViewClose
                    );
                }, 0);
            }
        }
    }
}
