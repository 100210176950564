import { URLManager } from './URLManager';
import { ContentManager } from './ContentManager';

function disablePinchZoom() {
    document.addEventListener(
        'touchstart',
        function (e) {
            if (e.touches.length > 1) {
                e.preventDefault();
            }
        },
        { passive: false }
    );
    document.addEventListener(
        'touchmove',
        function (e) {
            if (e.scale && e.scale !== 1) {
                e.preventDefault();
            }
        },
        { passive: false }
    );
}

disablePinchZoom();

const urlManager = new URLManager();
const contentManager = new ContentManager(urlManager);

window.app = {
    switchTab: (tab) => {
        urlManager.setParam('tab', tab);
        urlManager.setParam('applyLang', null);
        contentManager.updateTabBarImage(tab); // Updated method name
        contentManager.showContent(tab, null);
    },
    showApply: (lang) => {
        contentManager.storePreviousState();
        urlManager.setParam('applyLang', lang);
        contentManager.showContent(null, lang);
        contentManager.updateBackButton(lang);
    },
};
